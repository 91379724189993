@import 'bootstrap/dist/css/bootstrap.min.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&family=Rubik+Mono+One&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(150, 150, 150);
  background: linear-gradient(
    90deg,
    rgba(150, 150, 150, 1) 0%,
    rgba(255, 255, 255, 1) 73%
  );
}

.navbar-toggler-icon {
  color: white;
}

.navbar-toggler {
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  background: rgb(150, 150, 150);
  background: linear-gradient(
    90deg,
    rgba(150, 150, 150, 1) 0%,
    rgba(255, 255, 255, 1) 73%
  );
  height: 100vh;
  width: 100vw;
  top: none;
}

.test-text {
  margin-top: 0;
}

.testing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200;
}

.main-text {
  margin-top: 12%;
}

.main-home {
  margin-top: 27%;
}

#title-text {
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

#sub-text {
  font-family: Poppins, sans-serif;
}

/* Navbar styling */

.nav-title {
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

.hi {
  font-family: 'Rubik Mono One', sans-serif;
  font-size: 50;
}

.secondary-text {
  font-family: Poppins, sans-serif;
  text-align: center;
}

.project-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.projects {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.card-icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.card-icon {
  margin-left: 10;
  margin-right: 10;
}

#card-block {
  box-shadow: -8px 7px 5px 0px rgba(0, 0, 0, 0.38);
}

/* about page */

#about-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#about-row {
  margin-bottom: 10px;
}

@media all and (max-width: 500px) {
  .main-text {
    margin-top: 30%;
  }

  .main-home {
    margin-top: 90%;
  }

  #about-row {
    padding-bottom: 20%;
  }

  #col-card {
    padding-bottom: 10px;
  }

  #project-container-main {
    padding-bottom: 30%;
  }

  #col-card-last {
    padding-bottom: 30%;
  }

  #google-form-row {
    padding-bottom: 30%;
  }
}
